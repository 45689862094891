<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <div style="color: red;">(开发人员设置，请勿更改)</div>
    </div>

    <el-table
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="Id" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="名称" min-width="200">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.level" :key="index" :style="item===1?'padding-left: '+ scope.row.level +'em':''">|--</span>
          {{ scope.row.display_name }}
        </template>
      </el-table-column>

      <el-table-column label="路由" min-width="200">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="权重" min-width="160" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.weight" size="small" @change="updateWeight(scope.row)"></el-input-number>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="180px">
        <el-form-item label="所属栏目" prop="parent_id">
          <el-select v-model="form.parent_id" filterable clearable>
            <el-option label="顶级" :value="0"></el-option>

            <div v-for="(item, index) in treeList" :key="index">
              <el-option :label="item.display_name" :value="item.id">
                <span v-for="(sonItem, sonIndex) in item.level" :key="sonIndex" :style="sonItem===1?'padding-left: '+ item.level +'em':''">|--</span>
                {{ item.display_name }}
              </el-option>
            </div>
          </el-select>
        </el-form-item>

        <el-form-item label="名称" prop="display_name">
          <el-input type="text" v-model="form.display_name" clearable />
        </el-form-item>

        <el-form-item label="路由" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>

        <el-form-item label="权重" prop="weight">
          <el-input-number v-model="form.weight"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        listLoading: true,
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          create: '新增',
          update: '修改',
        },
        form: {
          id: '',
          parent_id: '',
          name: '',
          display_name: '',
          weight: this.initWeight,
        },
        rules: {
          parent_id: [{ required: true, message: '所属栏目不能为空', trigger: 'change' }],
          name: [{ required: true, message: '路由不能为空', trigger: 'change' }],
          display_name: [{ required: true, message: '名称不能为空', trigger: 'change' }],
        },
        btnLoading: false,
        // 树状列表
        treeList: null,
        // 初始化权重
        initWeight: 1,
        cache_parent_id: 0,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/backend/permission/all',
          method: 'get',
          params: {},
        }).then(response => {
          this.list = response.data
          this.listLoading = false
          this.getTreeList()
          this.getInitWeight()
        })
      },
      resetForm() {
        this.form = {
          id: '',
          parent_id: this.cache_parent_id,
          name: '',
          display_name: '',
          weight: this.initWeight,
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.cache_parent_id = this.form.parent_id
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: '/api/backend/permission/save',
              method: 'post',
              data: this.form
            }).then(response => {
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              console.log(err)
            }).finally(() => {
              this.btnLoading = false
            })
          }
        })
      },
      // 树状列表
      getTreeList() {
        request({
          url: '/api/backend/permission/all',
          method: 'get',
          params: {},
        }).then(response => {
          this.treeList = response.data
        })
      },
      // 获取初始化权重
      getInitWeight() {
        request({
          url: '/api/backend/permission/getInitWeight',
          method: 'get',
          params: {},
        }).then(response => {
          this.initWeight = response.data
        })
      },
      // 更新权重
      updateWeight(row){
        this.listLoading = true
        request({
          url: '/api/backend/permission/updateWeight',
          method: 'post',
          data: {id: row.id, weight: row.weight},
        }).then(response => {
          this.getList()
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.listLoading = false
        })
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
